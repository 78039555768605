<template>
  <v-container>
    <div v-if="!loading" class="mt-4 mx-2">
      <v-row>
        <v-btn
          v-if="!loading"
          :loading="getLoading"
          :disabled="getLoading"
          @click="getTotals()"
          >Get Employee Hours</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="!loading"
          :loading="payLoading"
          :disabled="
            (companyHours.martins.length === 0 &&
              companyHours.brothers.length === 0) ||
            payLoading
          "
          color="green accent-3"
          @click="payEmployees()"
          >Pay Employees</v-btn
        >
      </v-row>
      <v-row>
        <v-textarea
          v-if="note !== ''"
          auto-grow
          class="mt-3"
          solo
          label="Employee Hours"
          :value="note"
          persistent-hint
          hint="COPY THE TEXT AREA CONTENTS SOMEWHERE BEFORE YOU DO ANYTHING"
        ></v-textarea>
      </v-row>
    </div>
    <v-progress-linear
      v-else
      color="deep-purple accent-4"
      indeterminate
      rounded
      width="100%"
      height="6"
    ></v-progress-linear>
    <v-snackbar v-model="paySnackbar" color="green" timeout="5000">
      Successfully Completed Payment

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="paySnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar v-model="getSnackbar" color="red" timeout="5000">
      No Hours to Retrieve

      <template v-slot:action="{ attrs }">
        <v-btn color="white" text v-bind="attrs" @click="getSnackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
const api = require("../shared/api");
const _ = require("lodash");

export default {
  async created() {
    this.loading = true;
    this.$store.state.users = await api.getAllUsers();
    this.usersClone = _.cloneDeep(this.$store.state.users);
    this.loading = false;
  },
  data() {
    return {
      companyHours: {
        martins: [],
        brothers: [],
      },
      allUserHours: [],
      getLoading: false,
      loading: true,
      payLoading: false,
      usersClone: [],
      note: "",
      getSnackbar: false,
      paySnackbar: false,
    };
  },
  methods: {
    async payEmployees() {
      this.payLoading = true;
      if (
        this.companyHours.martins.length > 0 ||
        this.companyHours.brothers.length > 0
      ) {
        await _.forEach(this.usersClone, async (user) => {
          let updatedMartins = this.payMartins(user);
          let updatedBrothers = this.payBrothers(user);
          if (updatedMartins || updatedBrothers) {
            await api.updateHours(user.workdays, user.id);
          }
        });
        this.resetData();
      }
      this.payLoading = false;
      this.paySnackbar = true;
    },
    payMartins(user) {
      let updated = false;
      let hoursIndex = this.getMartinsHoursIndex(user.id);
      if (hoursIndex !== -1) {
        updated = true;
        let cIndex = user.workdays.findIndex(
          (company) => company.company === "Martins"
        );
        for (let i = 0; i < user.workdays[cIndex].hours.length && i < 30; i++) {
          if (
            "payed" in user.workdays[cIndex].hours[i] &&
            !user.workdays[cIndex].hours[i].payed
          ) {
            user.workdays[cIndex].hours[i].payed = true;
          }
        }
        let today = this.getCurrentDate();
        user.workdays[cIndex].hours.unshift({
          total: this.companyHours.martins[hoursIndex].company.total,
          payment: true,
          date: today,
        });
      }
      return updated;
    },
    payBrothers(user) {
      let updated = false;
      let hoursIndex = this.getBrothersHoursIndex(user.id);
      if (hoursIndex !== -1) {
        updated = true;
        let cIndex = user.workdays.findIndex(
          (company) => company.company === "4 Brothers"
        );
        for (let i = 0; i < user.workdays[cIndex].hours.length && i < 30; i++) {
          if (
            "payed" in user.workdays[cIndex].hours[i] &&
            !user.workdays[cIndex].hours[i].payed
          ) {
            user.workdays[cIndex].hours[i].payed = true;
          }
        }
        let today = this.getCurrentDate();
        user.workdays[cIndex].hours.unshift({
          total: this.companyHours.brothers[hoursIndex].company.total,
          payment: true,
          date: today,
        });
      }
      return updated;
    },
    getMartinsHoursIndex(id) {
      return this.companyHours.martins.findIndex((user) => user.id === id);
    },
    getBrothersHoursIndex(id) {
      return this.companyHours.brothers.findIndex((user) => user.id === id);
    },
    getCurrentDate() {
      let today = new Date();
      let dd = today.getDate();
      let mm = today.getMonth() + 1;
      let yyyy = today.getFullYear();
      if (dd < 10) {
        dd = "0" + dd;
      }
      if (mm < 10) {
        mm = "0" + mm;
      }
      today = mm + "/" + dd + "/" + yyyy;
      return today;
    },
    async getTotals() {
      this.getLoading = true;
      this.resetData();
      this.note = "";
      this.$store.state.users = await api.getAllUsers();
      this.usersClone = _.cloneDeep(this.$store.state.users);
      this.calculateTotals();
      this.organize();
      this.getNote();
      if (_.isEmpty(this.note)) {
        this.getSnackbar = true;
      }
      this.getLoading = false;
    },
    calculateTotals() {
      _.forEach(this.usersClone, (user) => {
        let userObj = {
          id: user.id,
          name: user.firstName,
          companyHours: [],
        };
        _.forEach(user.workdays, (company) => {
          let companyObj = {
            company: company.company,
            total: 0,
            types: [],
          };
          _.forEach(company.hours, (workday) => {
            if ("payed" in workday && !workday.payed) {
              let index = companyObj.types.findIndex(
                (element) => element.type === workday.type
              );
              if (index !== -1) {
                companyObj.types[index].typeTotal += workday.total;
              } else {
                companyObj.types.push({
                  type: workday.type,
                  typeTotal: workday.total,
                });
              }
              companyObj.total += workday.total;
            }
          });
          if (companyObj.total > 0) {
            userObj.companyHours.push(companyObj);
          }
        });
        this.allUserHours.push(userObj);
      });
    },
    getNote() {
      if (this.companyHours.martins.length > 0) {
        this.note += "Martins:\n";
        _.forEach(this.companyHours.martins, (user) => {
          this.note += `    ${user.name} - ${user.company.total} hours\n`;
        });
      }

      if (this.companyHours.brothers.length > 0) {
        if (this.companyHours.martins.length > 0)
          this.note += "\n4 Brothers:\n";
        else this.note += "4 Brothers:\n";
        _.forEach(this.companyHours.brothers, (user) => {
          this.note += `    ${user.name} - ${user.company.total} hours\n`;
          _.forEach(user.company.types, (type) => {
            if (!_.isEmpty(type.type)) {
              this.note += `        ${type.type} - ${type.typeTotal} hours\n`;
            }
          });
        });
      }
    },
    organize() {
      _.forEach(this.allUserHours, (user) => {
        _.forEach(user.companyHours, (company) => {
          if (company.company === "Martins") {
            this.companyHours.martins.push({
              name: user.name,
              id: user.id,
              company: { total: company.total, types: company.types },
            });
          } else {
            this.companyHours.brothers.push({
              name: user.name,
              id: user.id,
              company: { total: company.total, types: company.types },
            });
          }
        });
      });
    },
    resetData() {
      this.companyHours.martins = [];
      this.companyHours.brothers = [];
      this.allUserHours = [];
    },
  },
};
</script>
