var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"gray lighten-6"},[_c('div',{staticClass:"d-flex justify-space-between"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text ml-0 mb-4",attrs:{"color":"deep-purple accent-4"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.company)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}])},[_c('v-list',_vm._l((_vm.companies),function(company){return _c('v-list-item',{key:company,attrs:{"link":""},on:{"click":function($event){return _vm.changeCompany(company)}}},[_c('v-list-item-title',[_vm._v(_vm._s(company))])],1)}),1)],1),_c('v-btn',{staticClass:"white--text ml-0",attrs:{"color":"deep-purple accent-4"},on:{"click":_vm.addWorkday}},[_vm._v("Add "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-plus ")])],1)],1),_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"pa-0"},[_c('v-spacer'),_c('v-chip',{attrs:{"text-color":"deep-purple accent-3","color":"white"}},[_c('span',{},[_vm._v("Biweekly Total: "+_vm._s(_vm.total))])])],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"hours-table","footer-props":{
        disableItemsPerPage: true,
      },"headers":_vm.headers,"items":_vm.items,"item-class":_vm.getRowColor,"items-per-page":25,"loading":_vm.loading,"disable-sort":true,"mobile-breakpoint":0},on:{"click:row":_vm.editWorkday},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
      var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.trimDate(item.date)))])]}},{key:"item.time",fn:function(ref){
      var item = ref.item;
return [(item.startTime)?_c('span',[_vm._v(_vm._s(item.startTime)+"-"+_vm._s(item.endTime))]):_c('span')]}}],null,true)})],1),(_vm.dialog)?_c('workday-dialog',{attrs:{"operation":_vm.operation,"dialog":_vm.dialog,"rowData":_vm.editRow,"company":_vm.company,"workdays":_vm.items},on:{"cancelDialog":_vm.cancelDialog,"deleteWorkday":_vm.deleteWorkday,"saveWorkday":_vm.saveWorkday}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }