<template>
  <v-container class="gray lighten-6">
    <div class="d-flex justify-space-between">
      <v-menu offset-y>
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            color="deep-purple accent-4"
            class="white--text ml-0 mb-4"
            v-bind="attrs"
            v-on="on"
          >
            {{ company }}
            <v-icon right> mdi-menu-down </v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item
            v-for="company in companies"
            @click="changeCompany(company)"
            :key="company"
            link
          >
            <v-list-item-title>{{ company }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-btn
        @click="addWorkday"
        color="deep-purple accent-4"
        class="white--text ml-0"
        >Add <v-icon right> mdi-plus </v-icon></v-btn
      >
    </div>
    <v-card elevation="0">
      <v-card-title class="pa-0">
        <v-spacer></v-spacer>
        <v-chip text-color="deep-purple accent-3" color="white">
          <span class="">Biweekly Total: {{ total }}</span>
        </v-chip>
      </v-card-title>
      <v-data-table
        id="hours-table"
        :footer-props="{
          disableItemsPerPage: true,
        }"
        :headers="headers"
        :items="items"
        :item-class="getRowColor"
        :items-per-page="25"
        :loading="loading"
        :disable-sort="true"
        :mobile-breakpoint="0"
        class="elevation-1"
        @click:row="editWorkday"
      >
        <template v-slot:[`item.date`]="{ item }">
          <span>{{ trimDate(item.date) }}</span>
        </template>
        <template v-slot:[`item.time`]="{ item }">
          <span v-if="item.startTime"
            >{{ item.startTime }}-{{ item.endTime }}</span
          >
          <span v-else></span>
        </template>
      </v-data-table>
    </v-card>
    <workday-dialog
      v-if="dialog"
      @cancelDialog="cancelDialog"
      @deleteWorkday="deleteWorkday"
      @saveWorkday="saveWorkday"
      :operation="operation"
      :dialog="dialog"
      :rowData="editRow"
      :company="company"
      :workdays="items"
    ></workday-dialog>
  </v-container>
</template>

<script>
import WorkdayDialog from "../components/WorkdayDialog.vue";
import { formatDate, isEmpty } from "../utils/utils";
const api = require("../shared/api");
const _ = require("lodash");

export default {
  components: {
    WorkdayDialog,
  },
  data: () => ({
    company: "",
    companies: ["Martins", "4 Brothers"],
    dialog: false,
    editRow: {},
    editSlot: {},
    headers: [
      {
        text: "Date",
        align: "start",
        sortable: false,
        value: "date",
      },
      {
        text: "Work Type",
        value: "type",
      },
      {
        text: "Time",
        value: "time",
      },
      {
        text: "Total",
        value: "total",
      },
    ],
    items: [],
    loading: true,
    operation: "",
    total: 0,
  }),
  created() {
    //this.items = _.cloneDeep(this.$store.state.workdays);
    this.company = this.$store.state.preferences.firstCompany;
    this.changeCompany(this.company);
    this.loading = false;
  },
  methods: {
    addWorkday() {
      this.operation = "Add";
      this.dialog = true;
    },
    changeCompany(company) {
      this.company = company;
      let companyArr = _.filter(this.$store.state.workdays, (workday) => {
        return workday.company === company;
      });
      if (companyArr && companyArr.length > 0) {
        this.items = companyArr[0].hours;
      } else {
        this.items = [];
      }
      this.calculateTotal();
    },
    cancelDialog() {
      this.editRow = {};
      this.dialog = false;
    },
    async deleteWorkday() {
      this.items.splice(this.editSlot.index, 1);
      await this.updateWorkdays();
      this.editRow = {};
      this.dialog = false;
    },
    editWorkday(row, slot) {
      if ("payed" in row) {
        this.editRow = row;
        this.editSlot = slot;
        this.operation = "Edit";
        this.dialog = true;
      }
    },
    formatDate: formatDate,
    isEmpty: isEmpty,
    getCompanyIndex() {
      let index = -1;
      _.forEach(this.$store.state.workdays, (element, i) => {
        if (element.company === this.company) {
          index = i;
        }
      });
      return index;
    },
    getRowColor(row) {
      if (row.payment) {
        return "yellow-row";
      } else if (row.payed) {
        return "green-row";
      } else {
        return "default-row";
      }
    },
    calculateTotal() {
      let tmp = 0;
      for (let i = 0; i < this.items.length && i < 30; i++) {
        if ("payed" in this.items[i] && !this.items[i].payed) {
          tmp += this.items[i].total;
        }
      }
      this.total = tmp;
    },
    trimDate(date) {
      // expects MM/DD/YYYY returns MM/DD
      if (date) {
        return date.slice(0, -5);
      } else {
        return "";
      }
    },
    async saveWorkday(workday) {
      let workdayClone = { ...workday };
      workdayClone.date = formatDate(workdayClone.date);
      if (this.operation === "Add") {
        // Add item
        this.items.unshift(workdayClone);
        this.items[0]["payed"] = false;
      } else {
        // Edit item
        this.items[this.editSlot.index] = workdayClone;
        this.items = _.cloneDeep(this.items);
        this.editRow = {};
      }
      await this.updateWorkdays();
      this.dialog = false;
    },
    async updateWorkdays() {
      this.$emit("updating");
      let index = this.getCompanyIndex();
      if (index === -1) {
        if (!this.$store.state.workdays) this.$store.state.workdays = [];
        this.$store.state.workdays.push({
          company: this.company,
          hours: this.items,
        });
      } else {
        this.$store.state.workdays[index].hours = this.items;
      }
      await api.updateHours(
        this.$store.state.workdays,
        this.$store.state.user.id
      );
      this.calculateTotal();
    },
  },
  watch: {
    company() {},
  },
};
</script>

<style>
.v-application--is-ltr .v-data-footer__select {
  display: none;
}

.v-data-footer {
  justify-content: flex-end;
}

.yellow-row {
  background-color: rgb(255, 243, 132);
}

#hours-table > div.v-data-table__wrapper > table > tbody > tr.yellow-row:hover {
  background-color: rgb(255, 247, 175);
}

.green-row {
  background-color: rgb(224, 255, 210);
}

#hours-table > div.v-data-table__wrapper > table > tbody > tr.green-row:hover {
  background-color: rgb(235, 255, 226);
}

.default-row {
  background-color: rgb(243, 243, 243);
}

#hours-table
  > div.v-data-table__wrapper
  > table
  > tbody
  > tr.default-row:hover {
  background-color: rgb(248, 248, 248);
}
</style>
