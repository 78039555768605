<template>
  <div class="small">
    <pie-chart :chart-data="datacollection"></pie-chart>
  </div>
</template>

<script>
import PieChart from "./PieChart";

export default {
  components: {
    PieChart,
  },
  data() {
    return {
      labelArr: [],
      datacollection: {},
    };
  },
  mounted() {
    this.setData();
    this.fillData();
  },
  methods: {
    fillData() {
      this.datacollection = {
        labels: this.labelArr,
        datasets: [
          {
            label: "All",
            backgroundColor: ["#f87979", "#087979", "#084579", "#087342"],
            data: [58, 12, 22, 42],
          },
        ],
      };
    },
    setData() {
      this.labelArr = ["Admin", "Assemble", "Construction", "Delivery"];
    },
  },
  props: ["users"],
};
</script>

<style>
.small {
  max-width: 600px;
  margin: 150px auto;
}
</style>
