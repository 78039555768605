<template>
  <div id="app">
    <v-app>
      <amplify-authenticator v-if="!signedIn">
        <amplify-sign-out></amplify-sign-out>
      </amplify-authenticator>
      <div v-else>
        <v-system-bar color="deep-purple darken-3"></v-system-bar>

        <v-app-bar color="deep-purple accent-4" dark>
          <v-app-bar-nav-icon
            @click.stop="drawer = !drawer"
          ></v-app-bar-nav-icon>
          <v-toolbar-title>{{ page }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-app-bar>

        <v-navigation-drawer
          id="nav-drawer"
          fixed
          v-model="drawer"
          absolute
          temporary
        >
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                class="deep-purple--text text--accent-4 text-h6"
              >
                Navigation
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-divider></v-divider>
          <v-list nav dense>
            <v-list-item-group
              v-model="group"
              active-class="deep-purple--text text--accent-4"
            >
              <v-list-item
                :key="item"
                v-for="item in listItems"
                @click="changePage(item)"
              >
                <v-list-item-title>{{ item }}</v-list-item-title>
              </v-list-item>
            </v-list-item-group>
          </v-list>
          <template v-slot:append>
            <v-card id="signout-card" color="deep-purple accent-4 ma-2">
              <amplify-authenticator @click="drawer = false">
                <amplify-sign-out></amplify-sign-out>
              </amplify-authenticator>
            </v-card>
          </template>
        </v-navigation-drawer>
        <SetupInfo
          v-if="!loading && setupDialog"
          :dialog="setupDialog"
          @saveSetup="saveSetup"
        />
        <Hours v-else-if="!loading && page === 'My Hours'" />
        <Admin v-else-if="!loading && page === 'Admin Payment'" />
        <AllEmployeeHours
          v-else-if="!loading && page === 'All Employees Hours'"
        />
        <v-progress-linear
          v-else
          color="deep-purple accent-4"
          indeterminate
          rounded
          width="100%"
          height="6"
        ></v-progress-linear>
      </div>
    </v-app>
  </div>
</template>

<script>
import Admin from "./views/Admin.vue";
import AllEmployeeHours from "./views/AllEmployeeHours.vue";
import Hours from "./views/Hours.vue";
import SetupInfo from "./components/SetupInfo.vue";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
const api = require("./shared/api");
export default {
  components: {
    Admin,
    AllEmployeeHours,
    Hours,
    SetupInfo,
  },
  data() {
    return {
      user: {},
      signedIn: false,
      drawer: false,
      setupInfo: false,
      setupDialog: false,
      flag: false,
      group: null,
      page: "",
      listItems: ["Admin Payment", "All Employees Hours", "My Hours"],
      loading: true,
    };
  },
  created() {
    this.loading = true;
    onAuthUIStateChange((state, user) => {
      if (state === AuthState.SignedIn) {
        // signout button color
        document.documentElement.style.setProperty(
          "--amplify-primary-color",
          "transparent"
        );
        this.signedIn = true;
        this.user = user;
      } else {
        // signup page color
        document.documentElement.style.setProperty(
          "--amplify-primary-color",
          "blue"
        );
        this.signedIn = false;
      }
    });
  },
  methods: {
    changePage(page) {
      this.page = page;
    },
    isAdmin() {
      return this.$store.state.user.role === "admin";
    },
    isEmployee() {
      return this.$store.state.user.role === "employee";
    },
    postSignIn() {
      this.$store.state.workdays = this.$store.state.user.workdays;
      this.$store.state.preferences = this.$store.state.user.preferences;
      if (this.isEmployee()) {
        this.listItems.splice(0, 2);
        this.page = "My Hours";
      } else {
        this.page = "Admin Payment";
      }
      this.loading = false;
    },
    async saveSetup(setupData) {
      this.loading = true;
      this.$store.state.user = await api.addUser(this.user, setupData);
      this.postSignIn();
      this.setupDialog = false;
    },
    async updateHours() {
      await api.updateHours(
        this.$store.state.workdays,
        this.$store.state.user.id
      );
    },
  },
  watch: {
    group() {
      this.drawer = false;
    },
    async signedIn() {
      if (this.signedIn) {
        this.$store.state.user = await api.getUserInfo();
        if (!this.$store.state.user || !this.$store.state.user.firstName) {
          this.setupDialog = true;
          this.loading = false;
        } else {
          this.postSignIn();
        }
      }
    },
  },
};
</script>

<style>
:root {
  --amplify-primary-color: blue;
  --amplify-primary-tint: rgb(53, 107, 255);
  --amplify-primary-shade: lightblue;
  --amplify-primary-shade: lightgray;
}
html {
  overflow: auto !important;
}
#main-card {
  height: 100vh;
}
amplify-button > button {
  min-width: 20px;
}
#nav-drawer {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}
</style>
