var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[(!_vm.loading)?_c('div',[_c('v-row',{staticClass:"ma-0"},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text ml-0 mr-2 mb-4",attrs:{"color":"deep-purple accent-4"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.company)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,2257519549)},[_c('v-list',_vm._l((_vm.companies),function(company){return _c('v-list-item',{key:company,attrs:{"link":""},on:{"click":function($event){return _vm.changeCompany(company)}}},[_c('v-list-item-title',[_vm._v(_vm._s(company))])],1)}),1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text ml-0 mb-4",attrs:{"color":"grey darken-4"}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.activeUser.firstName)+" "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-menu-down ")])],1)]}}],null,false,2217442499)},[_c('v-list',_vm._l((_vm.usersClone),function(user){return _c('v-list-item',{key:user.id,attrs:{"link":""},on:{"click":function($event){return _vm.changeUser(user)}}},[_c('v-list-item-title',[_vm._v(_vm._s(user.firstName)+" "+_vm._s(user.lastName))])],1)}),1)],1),_c('v-spacer'),_c('v-btn-toggle',{attrs:{"mandatory":""},model:{value:(_vm.dataView),callback:function ($$v) {_vm.dataView=$$v},expression:"dataView"}},[_c('v-btn',{attrs:{"small":""}},[_vm._v("Data Table")]),_c('v-btn',{attrs:{"small":""}},[_vm._v("Chart")])],1)],1),(_vm.dataView === 0)?_c('v-data-table',{staticClass:"elevation-1",attrs:{"id":"all-hours-table","footer-props":{
        disableItemsPerPage: true,
      },"headers":_vm.headers,"items":_vm.items,"item-class":_vm.getRowColor,"items-per-page":25,"loading":_vm.loading,"disable-sort":true,"mobile-breakpoint":0}}):_c('EmployeesChart',{attrs:{"users":_vm.usersClone}})],1):_c('v-progress-linear',{attrs:{"color":"deep-purple accent-4","indeterminate":"","rounded":"","width":"100%","height":"6"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }