import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    workdays: null,
    user: null,
    users: null,
    preferences: null,
  },
  mutations: {},
  actions: {},
  modules: {},
});
