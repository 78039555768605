<template>
  <v-container>
    <div v-if="!loading">
      <v-row class="ma-0">
        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              color="deep-purple accent-4"
              class="white--text ml-0 mr-2 mb-4"
              v-bind="attrs"
              v-on="on"
            >
              {{ company }}
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="company in companies"
              @click="changeCompany(company)"
              :key="company"
              link
            >
              <v-list-item-title>{{ company }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-menu offset-y>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              color="grey darken-4"
              class="white--text ml-0 mb-4"
              v-bind="attrs"
              v-on="on"
            >
              {{ activeUser.firstName }}
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              v-for="user in usersClone"
              @click="changeUser(user)"
              :key="user.id"
              link
            >
              <v-list-item-title
                >{{ user.firstName }} {{ user.lastName }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn-toggle v-model="dataView" mandatory>
          <v-btn small>Data Table</v-btn>
          <v-btn small>Chart</v-btn>
        </v-btn-toggle>
      </v-row>
      <v-data-table
        v-if="dataView === 0"
        id="all-hours-table"
        :footer-props="{
          disableItemsPerPage: true,
        }"
        :headers="headers"
        :items="items"
        :item-class="getRowColor"
        :items-per-page="25"
        :loading="loading"
        :disable-sort="true"
        :mobile-breakpoint="0"
        class="elevation-1"
      ></v-data-table>
      <EmployeesChart v-else :users="usersClone" />
    </div>
    <v-progress-linear
      v-else
      color="deep-purple accent-4"
      indeterminate
      rounded
      width="100%"
      height="6"
    ></v-progress-linear>
  </v-container>
</template>

<script>
import EmployeesChart from "../components/EmployeesChart.vue";
const api = require("../shared/api");
const _ = require("lodash");

export default {
  components: {
    EmployeesChart,
  },
  async created() {
    this.$store.state.users = await api.getAllUsers();
    this.usersClone = _.cloneDeep(this.$store.state.users);
    this.activeUser = this.usersClone[0];
    this.changeUser(this.activeUser);
    this.loading = false;
  },
  data() {
    return {
      activeUser: {},
      company: "Martins",
      dataView: 0,
      companies: ["Martins", "4 Brothers"],
      headers: [
        {
          text: "Date",
          align: "start",
          sortable: false,
          value: "date",
        },
        {
          text: "Work Type",
          value: "type",
        },
        {
          text: "Time",
          value: "time",
        },
        {
          text: "Total",
          value: "total",
        },
      ],
      isTableView: true,
      items: [],
      loading: true,
      usersClone: [],
    };
  },
  methods: {
    changeCompany(company) {
      this.company = company;
      this.changeUser(this.activeUser);
    },
    changeUser(user) {
      this.activeUser = user;
      let cIndex = this.activeUser.workdays.findIndex(
        (company) => company.company === this.company
      );
      if (cIndex !== -1) {
        this.items = this.activeUser.workdays[cIndex].hours;
      } else {
        this.items = [];
      }
    },
    getRowColor(row) {
      if (row.payment) {
        return "yellow-row";
      } else if (row.payed) {
        return "green-row";
      } else {
        return "default-row";
      }
    },
  },
  watch: {
    dataView() {
      //console.log(this.dataView);
    },
  },
};
</script>

<style>
.v-application--is-ltr .v-data-footer__select {
  display: none;
}

.v-data-footer {
  justify-content: flex-end;
}

.yellow-row {
  background-color: rgb(255, 243, 132);
}

#all-hours-table
  > div.v-data-table__wrapper
  > table
  > tbody
  > tr.yellow-row:hover {
  background-color: rgb(255, 247, 175);
}

.green-row {
  background-color: rgb(224, 255, 210);
}

#all-hours-table
  > div.v-data-table__wrapper
  > table
  > tbody
  > tr.green-row:hover {
  background-color: rgb(235, 255, 226);
}

.default-row {
  background-color: rgb(243, 243, 243);
}

#all-hours-table
  > div.v-data-table__wrapper
  > table
  > tbody
  > tr.default-row:hover {
  background-color: rgb(248, 248, 248);
}
</style>
