import { API } from "aws-amplify";

export async function addUser(user, setupData) {
  let hours = null;
  await API.post("martinsApi", "/addUser", {
    body: {
      id: user.attributes.sub,
      username: user.username,
      workdays: [],
      role: "employee",
      firstName: setupData.firstName,
      lastName: setupData.lastName,
      preferences: { firstCompany: setupData.firstCompany },
    },
  })
    .then((result) => {
      hours = JSON.parse(result.body);
    })
    .catch((err) => {
      console.log(err);
    });
  return hours;
}

export async function getUserInfo() {
  let hours = null;
  await API.get("martinsApi", "/hours", {})
    .then((result) => {
      let res = JSON.parse(result.body);
      if (res.length > 0) {
        hours = JSON.parse(result.body)[0];
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return hours;
}

export async function getAllUsers() {
  let users = [];
  await API.get("martinsApi", "/users", {})
    .then((result) => {
      let res = JSON.parse(result.body);
      if (res.length > 0) {
        users = JSON.parse(result.body);
      }
    })
    .catch((err) => {
      console.log(err);
    });
  return users;
}

export async function updateHours(workdays, userId) {
  await API.put("martinsApi", "/hours", {
    body: {
      userId: userId,
      workdays: workdays,
    },
  })
    .then((result) => {
      workdays = JSON.parse(result.body).workdays;
    })
    .catch((err) => {
      console.log(err);
    });
}

export async function updatePreferences(preferences) {
  console.log("updatePreferences");
  await API.put("martinsApi", "/hours", {
    body: {
      preferences: preferences,
    },
  })
    .then((result) => {
      preferences = JSON.parse(result.body).preferences;
    })
    .catch((err) => {
      console.log(err);
    });
}
